<template>
    <div>
        <div class="home_index"></div>
        <div class="nav_box">
            <Classification :types="1" :navData="$store.state.navData" :sidebarData="sidebarData"></Classification>
        </div>
        <div class="main flex">
            <div class="leftSidebar"></div>
            <div class="cont_box">
                <div class="banner_box flex">
                    <div class="banner">
                        <el-carousel :interval="5000" arrow="always">
                            <el-carousel-item v-for="(item, index) in bannerData" :key="index">
                                <img class="pointer" v-if="item.adImageUrl" :src="item.adImageUrl" alt=""
                                    @click="bannerBtn(item.link)" />
                            </el-carousel-item>
                        </el-carousel>
                    </div>
                    <div class="rightSidebar">
                        <div class="userInfo_box flex">
                            <img @click="toPerson" v-if="$store.state.userData.token" class="userImg pointer" :src="
                                $store.state.userData.headImgUrl
                                    ? $store.state.userData.headImgUrl
                                    : isUserImg
                            " alt="" />
                            <div class="user_info">
                                <div class="hi_hello">Hi!~ 您好</div>
                                <div class="welcome" v-if="$store.state.userData.token">
                                    {{
                                        $store.state.userData.nickname
                                        ? $store.state.userData.nickname
                                        : $store.state.userData.phone
                                    }}
                                </div>
                                <div class="welcome" v-else>欢迎光临筑手建筑防护超市</div>
                            </div>
                        </div>

                        <div class="orderBox Around" v-if="$store.state.userData.token">
                            <div class="orderList display column pointer" @click="toOrder(1)">
                                <div class="ordreNum">
                                    {{ $store.state.orderNum.stayPaymentNum }}
                                </div>
                                <div class="ordreStatus">待付款</div>
                            </div>
                            <div class="orderList display column pointer" @click="toOrder(2)">
                                <div class="ordreNum">
                                    {{ $store.state.orderNum.stayShipmentsNum }}
                                </div>
                                <div class="ordreStatus">待发货</div>
                            </div>
                            <div class="orderList display column pointer" @click="toOrder(3)">
                                <div class="ordreNum">
                                    {{ $store.state.orderNum.pendingReceiptNum }}
                                </div>
                                <div class="ordreStatus">待收货</div>
                            </div>
                        </div>
                        <div class="logo_box Between" v-else>
                            <div @click="toLogin(1)" class="logoBtn display pointer">
                                登录
                            </div>
                            <div @click="toLogin(3)" class="registeredBtn display pointer">
                                免费注册
                            </div>
                        </div>
                        <div class="line"></div>
                        <div>
                            <div class="home_service Between flex-wrap">
                                <div class="list display column pointer" @click="serviceBtn(item.name)"
                                    v-for="(item, index) in home_service" :key="index">
                                    <img :src="item.url" alt="" />
                                    <div class="name">{{ item.name }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="cont_box_List">
                    <div class="list flex">
                        <img class="pointer" v-if="item.state == 1" @click="toLink(item.link)"
                            v-for="(item, index) in categoryAds" :src="item.adImageUrl" :key="index" alt="" />
                    </div>
                </div>
            </div>
        </div>
        <div class="goods_main">
            <div class="hot_recommended hot" id="id0">
                <div class="hot_head flex">
                    <div class="hot">火热推荐</div>
                    <div class="recommended">海量货源 实时推荐</div>
                </div>
                <div class="swiper_box">
                    <swiper class="swiper" ref="swiper" :options="swiperOption">
                        <swiper-slide v-for="(item, index) in hotData" :key="index">
                            <div class="goodsBox pointer" @click="toGoodsDetails(item.id)">
                                <div class="goodsImg">
                                    <img :src="item.coverUrl" alt="" :title="item.productName" />
                                </div>
                                <div class="goodsName row">{{ item.productName }}</div>
                                <div class="goodsPrice_box Between">
                                    <div class="price">￥{{ item.price.toFixed(2) }}</div>
                                    <div class="buyBtn pointer">抢购</div>
                                </div>
                            </div>
                        </swiper-slide>
                        <div class="swiper-button-prev" slot="button-prev">
                            <img class="icon_left_right" src="../../assets/img/left.png" alt="" />
                        </div>
                        <div class="swiper-button-next" slot="button-next">
                            <img class="icon_left_right" src="../../assets/img/right.png" alt="" />
                        </div>
                    </swiper>
                </div>
            </div>
            <div class="hot_recommended scenario" id="id1">
                <div class="hot_head flex">
                    <div class="hot">场景广场</div>
                </div>
                <div class="swiper_box">
                    <swiper class="swiper" ref="swiper" :options="swiperOptions">
                        <swiper-slide v-for="(item, index) in scenarioData" :key="index">
                            <div class="goodsBox pointer display column" @click="toScene(item, index)">
                                <div class="goodsImg">
                                    <img class="goods_img" :src="item.scenesUrl" alt="" />
                                </div>
                                <div class="goods_cont display column">
                                    <div class="goods_name">{{ item.classificationName }}</div>
                                    <!-- <div class="goods_details">{{item.goodsDetails}}</div> -->
                                    <div class="goods_details">{{ item.briefIntroduce }}</div>
                                </div>
                            </div>
                        </swiper-slide>

                        <div class="swiper-button-prev" slot="button-prev">
                            <img class="icon_left_right" src="../../assets/img/left.png" alt="" />
                        </div>
                        <div class="swiper-button-next" slot="button-next">
                            <img class="icon_left_right" src="../../assets/img/right.png" alt="" />
                        </div>
                    </swiper>
                </div>
            </div>

            <!-- <img class="minBanner" src="../../assets/img/minBanner.png" alt=""> -->
            <div class="goodsList_box" id="id2">
                <GoodsList :goodsListData="goodsListData"></GoodsList>
            </div>
        </div>
        <div class="scene_sidebar" v-if="fixed == true">
            <div :class="{ navAactive: index === navgatorIndex }" @click="handleBtn(index)"
                class="sidebar_list display pointer" v-for="(item, index) in sidebarLeft" :key="index">
                {{ item.name }}
                <div class="line" v-if="index != navgatorIndex"></div>
            </div>
            <div @click="toTop" class="sidebar_list pointer display column">
                <img class="icon_img" src="@/assets/img/upImgs.png" alt="" />
                顶部
            </div>
        </div>
    </div>
</template>
<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import Classification from '../../components/classification.vue'
import GoodsList from './components/goodsList.vue'
export default {
    components: {
        Swiper,
        SwiperSlide,
        GoodsList,
        Classification,
    },
    data() {
        return {
            isUserImg: require('@/assets/img/worker.png'),
            swiperOption: {
                slidesPerView: 5,
                spaceBetween: 20,
                direction: 'horizontal',
                observer: true,
                observeParents: true,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                on: {
                    resize: () => {
                        this.$refs.swiper.$swiper.changeDirection(
                            window.innerWidth <= 960 ? 'vertical' : 'horizontal'
                        )
                    },
                },
            },

            swiperOptions: {
                slidesPerView: 4,
                direction: 'horizontal',
                observer: true,
                observeParents: true,
                spaceBetween: 18,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                on: {
                    resize: () => {
                        this.$refs.swiper.$swiper.changeDirection(
                            window.innerWidth <= 960 ? 'vertical' : 'horizontal'
                        )
                    },
                },
            },
            sidebarData: [],
            bannerData: [
                {
                    id: 1,
                    url: require('../../assets/img/4.png'),
                },
                {
                    id: 2,
                    url: require('../../assets/img/2.png'),
                },
                {
                    id: 3,
                    url: require('../../assets/img/3.png'),
                },
            ],
            hotData: [],
            scenarioData: [],
            goodsListData: [],
            categoryAds: [],
            home_service: [
                {
                    id: 1,
                    url: require('@/assets/img/s1.png'),
                    name: '我的收藏',
                    urls: '/person/index',
                },
                {
                    id: 2,
                    url: require('@/assets/img/s2.png'),
                    name: '我的优惠券',
                    urls: '/person/index',
                },
                {
                    id: 3,
                    url: require('@/assets/img/s3.png'),
                    name: '认证会员',
                },
                {
                    id: 4,
                    url: require('@/assets/img/s4.png'),
                    name: '我要采购',
                },
                {
                    id: 5,
                    url: require('@/assets/img/s5.png'),
                    name: '订单中心',
                },
                {
                    id: 6,
                    url: require('@/assets/img/s6.png'),
                    name: '专属客服',
                },
            ],
            orderNum: {},
            sidebarLeft: [
                {
                    id: 1,
                    name: '火热推荐',
                },
                {
                    id: 2,
                    name: '场景广场',
                },
                {
                    id: 3,
                    name: '热销品类',
                },
            ],
            navgatorIndex: 0,
            listBoxState: true, //点击导航栏时，暂时停止监听页面滚动
            fixed: false,
            gotop: false,
        }
    },
    mounted() {
        this.init()

        if (
            this.$store.state.userData.token != '' &&
            this.$store.state.userData.token != undefined
        ) {
            this.getOrderNum()
        }
        let timeId
        window.addEventListener(
            'scroll',
            () => {
                // 页面滚动停止100毫秒后才会执行下面的函数。
                clearTimeout(timeId)
                timeId = setTimeout(() => {
                    this.scrollToTop()
                }, 100)
            },
            true
        )

        window.addEventListener('scroll', this.handleScroll, true)
        window.addEventListener('scroll', this.fixedActiveBtn)
    },
    methods: {
        //初始化数据
        init() {
            this.api
                .findHomeData({ token: this.$store.state.userData.token })
                .then((res) => {
                    this.bannerData = res.data.carousel || []
                    this.hotData = res.data.recommendProduct || []
                    this.scenarioData = res.data.scenesSquare || []
                    this.goodsListData = res.data.floorAdvertise
                    this.categoryAds = res.data.categoryAds

                    this.goodsListData.forEach((item) => {
                        item.productClassificationVOS = item.productClassificationVOS.slice(
                            0,
                            8
                        )
                        let params = {
                            token: this.$store.state.userData.token || '',
                            catId: item.productClassificationId,
                        }
                        this.api.homeFindClassificationProduct(params).then((datas) => {
                            let goodsLength = []
                            datas.data = datas.data.slice(0, 6)
                            goodsLength = datas.data.slice(0, 5)
                            this.$set(item, 'goodsList', datas.data)
                            this.$set(item, 'goodsLists', goodsLength)
                        })
                    })
                })
        },
        // 获取订到数量
        getOrderNum() {
            this.api
                .findOrderNum({ token: this.$store.state.userData.token })
                .then((res) => {
                    if (res.code == 0) {
                        this.$store.commit('getOrderNum', res.data)
                    }
                })
        },
        toLogin(type) {
            this.$router.push({
                path: '/login',
                query: {
                    type: type,
                },
            })
        },
        toScene(item) {
            let routeData = this.$router.resolve({
                path: '/scene/newScene',
                query: {
                    type: item.id,
                },
            })
            window.open(routeData.href, '_blank')
        },
        toGoodsDetails(id) {
            sessionStorage.removeItem('navItem')
            let routeData = this.$router.resolve({
                path: '/mall/goodsDetails',
                query: {
                    id: id,
                },
            })
            window.open(routeData.href, '_blank')
        },

        //
        toLink(url) {
            window.open(url, '_blank')
        },
        // 进入个人页
        toPerson: function () {
            sessionStorage.removeItem('broadside')
            this.$router.push('/person/index')
            // 每次进入个人-显示userCenter组件
        },

        //轮播图
        bannerBtn(link) {
            window.open(link, '_blank')
        },

        serviceBtn(name) {
            // 用户没有登录-跳转登录页
            if (name == '我要采购') {
                this.$router.push({
                    path: '/mall/goodsList',
                    query: {
                        type: 1,
                        id: '',
                        catGrade: 1,
                        goodsGrade: 1,
                        navName: '我要采购',
                    },
                })
                sessionStorage.removeItem('serveData')
                localStorage.removeItem('gradeData')
            } else {
                if (this.$store.state.userData.token) {
                    let url = '/person/index'
                    let type = ''
                    sessionStorage.removeItem('broadside')
                    if (name == '我的收藏') {
                        type = 8
                    } else if (name == '我的优惠券') {
                        type = 10
                    } else if (name == '认证会员') {
                        type = 18
                    } else if (name == '订单中心') {
                        type = '99'
                    } else if (name == '专属客服') {
                        type = 20
                    }
                    let routeData = this.$router.resolve({
                        path: url,
                        query: {
                            type: type,
                        },
                    })
                    window.open(routeData.href, '_blank')
                } else {
                    this.$message({
                        message: '您还没有登录账号！',
                        type: 'warning',
                    })
                    this.$router.push({
                        path: '/login',
                    })
                }
            }
        },
        toOrder(type) {
            sessionStorage.removeItem('broadside')
            let routeData = this.$router.resolve({
                path: '/person/index',
                query: {
                    type: type,
                },
            })
            window.open(routeData.href, '_blank')
        },
        handleBtn(index) {
            this.navgatorIndex = index
            this.$el.querySelector(`#id${index}`).scrollIntoView({
                behavior: 'smooth', // 平滑过渡
                block: 'start', // 上边框与视窗顶部平齐。默认值
            })
            this.listBoxState = false

            let timeId
            clearTimeout(timeId)
            timeId = setTimeout(() => {
                this.listBoxState = true
            }, 200)
        },
        // 监听页面元素滚动，改变导航栏选中
        scrollToTop() {
            // 获取视窗高度
            var domHight = document.body.offsetHeight

            // dom滚动位置
            var scrollTop =
                window.pageYOffset ||
                document.documentElement.scrollTop ||
                document.body.scrollTop

            if (this.listBoxState) {
                //作用是点击导航栏时，延迟这里执行。
                this.sidebarLeft.map((v, i) => {
                    // 获取监听元素距离视窗顶部距离
                    var offsetTop = document.getElementById(`id${i}`).offsetTop
                    // 获取监听元素本身高度
                    var scrollHeight = document.getElementById(`id${i}`).scrollHeight

                    // 如果 dom滚动位置 >= 元素距离视窗距离 && dom滚动位置 <= 元素距离视窗距离+元素本身高度
                    // 则表示页面已经滚动到可视区了。
                    if (scrollTop >= offsetTop && scrollTop <= offsetTop + scrollHeight) {
                        // 导航栏背景色选中
                        this.navgatorIndex = i
                    }
                })
            }
        },

        fixedActiveBtn() {
            var scrollTop =
                window.pageYOffset ||
                document.documentElement.scrollTop ||
                document.body.scrollTop
            scrollTop >= 500 //滚动的高度
                ? (this.fixed = true)
                : (this.fixed = false)
        },

        handleScroll() {
            let scrolltop =
                document.documentElement.scrollTop || document.body.scrollTop
            scrolltop > 30 ? (this.gotop = true) : (this.gotop = false)
        },
        //  回到顶部
        toTop() {
            this.navgatorIndex = 0
            let top = document.documentElement.scrollTop || document.body.scrollTop
            // 实现滚动效果
            const timeTop = setInterval(() => {
                document.body.scrollTop = document.documentElement.scrollTop = top -= 50
                if (top <= 0) {
                    clearInterval(timeTop)
                }
            }, 10)
        },
    },
}
</script>
<style lang="scss" scoped>
.home_index {
    min-width: 1200px;
    height: 500px;
    background: #ff6837;
}

.nav_box {
    margin-top: -500px;
}

.main {
    width: 1200px;
    height: 460px;
    margin: 0 auto;

    .leftSidebar {
        min-width: 232px;
        height: 460px;
        background: white;
    }

    .cont_box {
        width: 100%;

        .banner_box {
            .banner {
                width: 734px;
                height: 330px;
            }

            .rightSidebar {
                width: 234px;
                height: 330px;
                background: #ffffff;
                padding: 31px 13px;
                padding-top: 26px;
                border-top: 2px solid #e1251b;

                .hi_hello {
                    font-size: 20px;
                    color: #333333;
                    line-height: 30px;
                }

                .welcome {
                    font-size: 14px;
                    color: #666;
                }

                .logo_box {
                    margin-top: 30px;

                    div {
                        width: 100px;
                        height: 34px;
                        font-size: 16px;
                        border-radius: 3px;
                    }

                    .logoBtn {
                        color: #e1251b;
                        background: #ffffff;
                        border: 1px solid #e1251b;
                    }

                    .registeredBtn {
                        color: #ffffff;
                        background: #e62129;
                    }
                }

                .line {
                    height: 1px;
                    margin-top: 25px;
                    background: rgba(0, 0, 0, 0.2);
                }

                .home_service {
                    .list {
                        width: 33%;
                        margin-top: 15px;

                        img {
                            width: 28px;
                            height: 22px;
                        }

                        .name {
                            font-size: 12px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #666666;
                            margin-top: 8px;
                        }
                    }

                    .list:nth-child(3) {
                        img {
                            width: 30px;
                            height: 26px;
                        }
                    }

                    .list:nth-child(4) {
                        img {
                            width: 25px;
                            height: 25px;
                        }
                    }

                    .list:nth-child(5) {
                        img {
                            width: 23px;
                            height: 23px;
                        }
                    }

                    .list:nth-child(6) {
                        img {
                            width: 34px;
                            height: 24px;
                        }
                    }
                }

                .orderBox {
                    margin-top: 18px;

                    .orderList {
                        .ordreNum {
                            font-size: 18px;
                            font-family: Microsoft YaHei;
                            font-weight: bold;
                            color: #e1251b;
                        }

                        .ordreStatus {
                            font-size: 12px;
                            color: #333333;
                            margin-top: 8px;
                        }
                    }
                }
            }
        }

        .cont_box_List {
            height: 117px;
            margin-top: 13px;

            .list {
                img {
                    width: 229px;
                    height: 117px;
                    margin-left: 13px;
                }
            }
        }
    }
}

.goods_main {
    width: 1200px;
    margin: 0 auto;

    .hot_recommended {
        height: 379px;
        background: #ffffff;
        border-radius: 3px;
        padding: 26px 0px;
        margin-top: 30px;

        .hot_head {
            padding: 0px 30px;

            .hot {
                font-size: 30px;
                font-weight: bold;
                color: #333333;
                line-height: 20px;
            }

            .recommended {
                margin-top: 5px;
                margin-left: 30px;
                font-size: 16px;
                color: #888888;
                line-height: 20px;
            }
        }
    }

    .scenario {
        height: 381px;

        .swiper_box {
            .goodsBox {
                width: 100%;
            }

            .goodsImg {
                .goods_img {
                    width: 278px;
                    height: 200px;
                }
            }
        }

        .goods_cont {
            .goods_name {
                font-size: 20px;
                font-weight: bold;
                color: #333333;
                margin-top: 20px;
            }

            .goods_details {
                font-size: 14px;
                color: #666666;
                margin-top: 10px;
            }
        }
    }

    .swiper_box {
        margin-top: 23px;

        .goodsBox:hover {
            .goodsName {
                color: #e1251b;
            }
        }

        .goodsBox {
            width: 180px;

            .goodsImg {
                img {
                    width: 180px;
                    height: 180px;
                }
            }

            .goodsName {
                width: 167px;
                font-size: 14px;
                color: #333333;
                margin-top: 16px;
                margin-bottom: 18px;
                padding-left: 11px;
            }

            .goodsPrice_box {
                width: 180px;
                height: 28px;
                border: 1px solid #e1251b;

                .price {
                    width: 119px;
                    height: 26px;
                    line-height: 26px;
                    font-size: 16px;
                    font-weight: bold;
                    color: #e1251b;
                    padding-left: 17px;
                }

                .buyBtn {
                    width: 61px;
                    height: 26px;
                    background: #e1251b;
                    font-size: 16px;
                    color: #fefefe;
                    text-align: center;
                    line-height: 26px;
                }
            }
        }
    }
}

// .minBanner{
//     width: 1200px;
//     height: 120px;
//     margin-top: 30px ;
// }
.goodsList_box {
    margin-top: 30px;
    margin-bottom: 50px;
}

.footer_box {
    margin-top: 51px;
}

.userInfo_box {
    .userImg {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        margin-right: 15px;
    }
}

.scene_sidebar {
    position: fixed;
    top: 50%;
    right: 30px;
    -webkit-transform: translateY(-50%);
    background: white;
    z-index: 2;

    .navAactive {
        color: white !important;
        background: #e1251b;
    }

    .sidebar_list {
        width: 56px;
        min-height: 62px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        text-align: center;
        padding: 15px 12px;
        box-sizing: border-box;
        position: relative;

        .line {
            width: 50px;
            height: 1px;
            background: #eaeaea;
            position: absolute;
            left: 50%;
            bottom: 0;
            margin-left: -25px;
        }

        .icon_img {
            width: 16px;
            height: 10px;
            margin-bottom: 5px;
        }
    }
}
</style>
<style lang="scss">
.el-carousel__container {
    height: 330px;
}

.hot .swiper-container {
    padding-left: 30px;
}

.scenario .swiper-container {
    padding-left: 17px;
}

.scenario .swiper-slide {
    width: 278px !important;
}

.swiper-button-prev {
    left: 0px;
    width: 24px;
    height: 38px;
    background: rgba(51, 51, 51, 0.4);
    border-radius: 0px 4px 4px 0px;
}

.swiper-button-next {
    right: 0px;
    width: 24px;
    height: 38px;
    background: rgba(51, 51, 51, 0.4);
    border-radius: 4px 0px 0px 4px;
}

.swiper-button-prev:after,
.swiper-button-next:after {
    content: none;
}

.icon_left_right {
    width: 11px;
    height: 21px;
}
</style>
<template>
	<div>
		<div class="goodsBox" v-for="(item, index) in goodsListData" :key="index">
			<img
				class="minBanner"
				@click="toCeshi(item.advertiseVO.link)"
				v-if="item.advertiseVO != null"
				:src="item.advertiseVO.adImageUrl"
				alt=""
			/>
			<div class="goodsList_box" v-if="item.id != null">
				<div class="head_title Between">
					<div class="fl flex">
						<div class="title">{{ item.classificationName }}</div>
						<div class="navTab flex-center">
							<div
								class="navtab_list pointer"
								:class="activeIndex == items.id ? 'active' : ''"
								@click="navTabBtn(2, item, items)"
								v-for="(items, indexs) in item.productClassificationVOS"
								:key="indexs"
							>
								{{ items.classificationName }}
								<div v-if="activeIndex == items.id" class="line"></div>
							</div>
						</div>
					</div>
					<div class="fr more display pointer" @click="navTabBtn(1, item)">
						更多>>
					</div>
				</div>
				<div class="scenarioGoods flex">
					<div class="scenarioBg">
						<img class="scenarioBg_img" :src="item.imgUrl" alt="" />
					</div>
					<div class="goodsBox flex flex-wrap">
						<div
							class="goodsList pointer"
							v-for="(items, indexs) in item.goodsList"
							:key="indexs"
							@click="toDetails(items.id)"
						>
							<div class="goodsImg display">
								<img
									class="goodsImg_img"
									:src="items.coverUrl"
									:title="items.productName"
									alt=""
								/>
							</div>
							<div class="goodsName astrict">{{ items.productName }}</div>
							<div class="goodsPrice">￥{{ items.price.toFixed(2) }}</div>
						</div>
					</div>
					<div class="goods_ranking">
						<div
							class="goods_ranking_list flex pointer"
							v-for="(items, indexs) in item.goodsLists"
							:key="indexs"
							@click="toDetails(items.id)"
						>
							<div class="goodsImg_box">
								<img
									class="goods_ranking_goodsImg"
									:src="items.coverUrl"
									alt=""
									:title="items.productName"
								/>
								<span
									v-if="item.hot == 1"
									:class="item.hot == 1 ? 'hot_s' : ''"
									>{{ item.hot }}</span
								>
								<span
									v-if="item.hot == 2"
									:class="item.hot == 2 ? 'hot_ss' : ''"
									>{{ item.hot }}</span
								>
								<span
									v-if="item.hot == 3"
									:class="item.hot == 3 ? 'hot_sss' : ''"
									>{{ item.hot }}</span
								>
								<span v-if="item.hot == 4 || item.hot == 5">{{
									item.hot
								}}</span>
							</div>
							<div class="goods_cont_box">
								<div class="goods_ranking_goodsName astrict">
									{{ items.productName }}
								</div>
								<div class="goods_ranking_goodsPrice">
									￥{{ items.price.toFixed(2) }}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	props: {
		goodsListData: {
			tyle: Array,
			default: [],
		},
	},
	data() {
		return {
			activeIndex: 0,
			navDatas: [
				{
					id: 1,
					name: '防水卷材',
				},
				{
					id: 2,
					name: '防水涂料',
				},
				{
					id: 3,
					name: '防水砂浆',
				},
				{
					id: 4,
					name: '堵漏材料',
				},
				{
					id: 5,
					name: '功能防水材料',
				},
				{
					id: 6,
					name: '防水剂',
				},
				{
					id: 7,
					name: '防水构件',
				},
			],
			goodsList: [
				{
					id: 1,
					name: '铁红醇酸防锈漆 管道铁栏杆金属防锈漆',
					goodsImg: require('../../../assets/img/goodsImg1.png'),
					goodsPrice: 111,
				},
				{
					id: 2,
					name: '铁红醇酸防锈漆 管道铁栏杆金属防锈漆',
					goodsImg: require('../../../assets/img/goodsImg2.png'),
					goodsPrice: 222,
				},
				{
					id: 3,
					name: '代尔塔(Deltaplus)彩虹系列劳保钢包头透气四季款防砸防刺穿',
					goodsImg: require('../../../assets/img/goodsImg3.png'),
					goodsPrice: 333,
				},
				{
					id: 4,
					name: '铁红醇酸防锈漆 管道铁栏杆金属防锈漆',
					goodsImg: require('../../../assets/img/goodsImg4.png'),
					goodsPrice: 444,
				},
				{
					id: 5,
					name: '铁红醇酸防锈漆 管道铁栏杆金属防锈漆',
					goodsImg: require('../../../assets/img/goodsImg5.png'),
					goodsPrice: 555,
				},
				{
					id: 6,
					name: '铁红醇酸防锈漆 管道铁栏杆金属防锈漆',
					goodsImg: require('../../../assets/img/goodsImg1.png'),
					goodsPrice: 666,
				},
			],
			hotGoodsList: [
				{
					id: 1,
					name: '铁红醇酸防锈漆 管道铁栏杆金属防锈漆',
					goodsImg: require('../../../assets/img/goodsImg1.png'),
					goodsPrice: 111,
					hot: 1,
				},
				{
					id: 2,
					name: '铁红醇酸防锈漆 管道铁栏杆金属防锈漆',
					goodsImg: require('../../../assets/img/goodsImg2.png'),
					goodsPrice: 222,
					hot: 2,
				},
				{
					id: 3,
					name: '代尔塔(Deltaplus)彩虹系列劳保钢包头透气四季款防砸防刺穿',
					goodsImg: require('../../../assets/img/goodsImg3.png'),
					goodsPrice: 333,
					hot: 3,
				},
				{
					id: 4,
					name: '铁红醇酸防锈漆 管道铁栏杆金属防锈漆',
					goodsImg: require('../../../assets/img/goodsImg4.png'),
					goodsPrice: 444,
					hot: 4,
				},
				{
					id: 5,
					name: '铁红醇酸防锈漆 管道铁栏杆金属防锈漆',
					goodsImg: require('../../../assets/img/goodsImg5.png'),
					goodsPrice: 555,
					hot: 5,
				},
			],
		}
	},
	methods: {
		navTabBtn(catGrade, item, items) {
			this.activeIndex = item.id
			let goodsGrade = null
			let id = ''
			let navName = ''
			let gradeData = {
				grades: null,
				gradess: null,
				gradesss: null,
			}
			if (catGrade == 1) {
				goodsGrade = 2
				id = item.productClassificationId
				navName = item.classificationName
				gradeData.grades = item.productClassificationId
			} else {
				id = items.id
				goodsGrade = 3
				navName = item.classificationName + '>' + items.classificationName
				gradeData.grades = item.productClassificationId
				gradeData.gradess = items.id
			}
			localStorage.setItem('gradeData', JSON.stringify(gradeData))
			let routeData = this.$router.resolve({
				path: '/mall/goodsList',
				query: {
					id: id,
					catGrade: catGrade,
					goodsGrade: goodsGrade,
					navName: navName,
				},
			})
			window.open(routeData.href, '_blank')
		},

		toCeshi(url) {
			window.open(url, '_blank')
		},
		toDetails(id) {
			this.$router.push({
				path: '/mall/goodsDetails',
				query: {
					id: id,
				},
			})
		},
	},
}
</script>
<style lang="scss" scoped>
.goodsList_box {
	width: 1200px;
	height: 575px;
	background: white;
	padding-top: 29px;
	padding-left: 30px;
	box-sizing: border-box;
	margin-top: 30px;
	.head_title {
		padding-right: 30px;
		.fl {
			.title {
				font-size: 30px;
				font-weight: bold;
				color: #333333;
			}
			.navTab {
				font-size: 16px;
				color: #333333;
				margin-left: 29px;
				margin-top: 12px;
				.navtab_list {
					margin-right: 35px;
					position: relative;
					.line {
						height: 2px;
						background: #e1251b;
						position: absolute;
						bottom: -9px;
						left: 0;
					}
				}
				.active {
					color: #e1251b !important;
				}
			}
		}
		.more {
			width: 90px;
			height: 32px;
			border: 1px solid #b7b7b7;
			border-radius: 3px 3px 3px 3px;
			font-size: 14px;
			color: #555555;
		}
	}
	.scenarioGoods {
		margin-top: 22px;
		.scenarioBg {
			margin-right: 20px;
			.scenarioBg_img {
				width: 370px;
				height: 484px;
			}
		}
		.goodsBox {
			width: 562px;
			border: 1px solid #eaeaea;
			.goodsList:hover {
				.goodsName {
					color: #e62129;
				}
			}
			.goodsList:nth-child(3) {
				border-right: none !important;
			}
			.goodsList:nth-child(4) {
				border-bottom: none !important;
			}
			.goodsList:nth-child(5) {
				border-bottom: none !important;
			}
			.goodsList:nth-child(6) {
				border-bottom: none !important;
				border-right: none !important;
			}
			// .goodsList:last-child{
			//     border-right: 1px solid #eee !important
			// }
			.goodsList {
				width: 33.33%;
				height: 242px;
				padding: 11px 10px;
				padding-bottom: 20px;
				// border: 1px solid #eee;
				border-right: 1px solid #eee;
				border-bottom: 1px solid #eee;
				.goodsImg {
					.goodsImg_img {
						width: 128px;
						height: 128px;
					}
				}
				.goodsName {
					width: 171px;
					height: 33px;
					font-size: 12px;
					color: #333333;
					margin-top: 5px;
				}
				.goodsPrice {
					font-size: 18px;
					font-weight: bold;
					color: #e62129;
					margin-top: 15px;
				}
			}
		}
	}
	.goods_ranking {
		width: 217px;
		padding-top: 10px;
		padding-left: 8px;
		.goods_ranking_list:hover {
			.goods_ranking_goodsName {
				color: #e62129 !important;
			}
		}
		.goods_ranking_list:nth-child(5) {
			margin-bottom: 0px;
		}
		.goods_ranking_list {
			margin-bottom: 28px;
			box-sizing: border-box;
			.goodsImg_box {
				position: relative;
				.goods_ranking_goodsImg {
					width: 68px;
					height: 68px;
				}
				.hot_s {
					background: #e1251b;
				}
				.hot_ss {
					background: #f17100;
				}
				.hot_sss {
					background: #f1b500;
				}
				span {
					width: 16px;
					height: 14px;
					background: #d0d0d0;
					border-radius: 3px;
					position: absolute;
					top: 4px;
					left: 0px;
					font-size: 12px;
					color: #fff;
					text-align: center;
					line-height: 12px;
				}
			}
			.goods_cont_box {
				margin-left: 10px;
				.goods_ranking_goodsName {
					width: 116px;
					height: 26px;
					font-size: 12px;
					margin-top: 10px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #666666;
					line-height: 13px;
				}
				.goods_ranking_goodsPrice {
					font-size: 16px;
					color: #e1251b;
					margin-top: 8px;
				}
			}
		}
	}
	.minBanner {
		width: 1200px;
		height: 120px;
		margin: 30px 0;
	}
}
</style>